var sw = sw || {};

// Main fold slider
//-----------------------------------------------------------------
sw.homeslider = function() {
    var $slider = $('.js_sliderFold').removeClass('visuallyhidden');

    if (!$slider.length) {
        return;
    }

    $slider.slick({
        arrows: false,
        dots: true,
        dotsClass: 'home_slider__dots',
        pauseOnHover: false,
        pauseOnDotsHover: true,
        slidesToShow: 1,
        cssEase: 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
        autoplay: true,
        speed: 700,
        lazyLoad: 'ondemand',
        adaptiveHeight: true
    });

};



$(document).ready(function (){
    sw.homeslider();
});

