var $price = 0;
var $total = 0;
var $height = 0;
var $vatValue = parseFloat( $(".vat_value").text(),10 );
$vatValue = $vatValue / 100;

$( ".product__head--btn" ).click(function(e) {
    e.preventDefault();

    var $product = $(this);
    var $titleProduct = $product.attr("title");
    var $priceAdd = parseInt( $product.data("price") );

    


    // If product is selected remove it and remove class
    if($product.hasClass('product--selected')) {
        //$height -= 24;
        $fintext = $("textarea.products__input").text().replace($titleProduct+'\n', '');
        //$("textarea.products__input").text($fintext).height($height);
        $("textarea.products__input").text($fintext);
        $product.removeClass("product--selected");
        
        if($product.hasClass('product--hr')) {
            $product.text("Odaberi HR");
        }
        else if($product.hasClass('product--en')) {
            $product.text("Odaberi EN");
        }
        else {
            $product.text("Odaberi");
        }

        $price = $price - $priceAdd;
        $vat = $price * $vatValue;
        $total = $price + $vat;

        $priceF = $price.toFixed(2);
        $vatF = $vat.toFixed(2);
        $totalF = $total.toFixed(2);

        $(".products__price").val($priceF +' kn');
        $(".products__price__vat").val('+PDV '+ $vatF +' kn');
        $(".products__price__total").val($totalF +' kn');
    } else {

        // Else if product didn't selected add class
        $product.addClass("product--selected").text("Odabrano");
        //$height += 24;
        //$("textarea.products__input").append($titleProduct+'\n').height($height);
        $("textarea.products__input").append($titleProduct+'\n');

        $price = $price + $priceAdd;
        $vat = $price * $vatValue;
        $total = $price + $vat;

        $priceF = $price.toFixed(2);
        $vatF = $vat.toFixed(2);
        $totalF = $total.toFixed(2);
        
        $(".products__price").val($priceF +' kn');
        $(".products__price__vat").val('+PDV '+ $vatF +' kn');
        $(".products__price__total").val($totalF + ' kn');
    }

});

function checkHeight() {
    var maxHeight = 0;
    var viewportWidth = $(window).width();

    if(viewportWidth > 768) {
        $(".product__head--info").each(function(){
           if ($(this).siblings('.product__head--img').height() > maxHeight) { maxHeight = $(this).siblings('.product__head--img').height(); }
            $(this).css({'min-height' : maxHeight});
        });
    } else {
        $(".product__head--info").each(function(){
            $(this).css({'min-height' : 'inherit'});
        });
    }


}

$(window).on('resize', checkHeight);
$(document).ready(checkHeight);

$(document).ready(function() {
    $("textarea.products__input").attr('readonly', true);

    $("textarea.products__input").change(function() {
        $(this).attr('readonly', true);
    });
});
