var sw = sw || {};

$ww = $(window).width();
$isiphone = (navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i));

sw.toggleMenu = function() {

    var $toggleButton = $('.js_toggleMenu'),
        $drawerWraper = $('.menu');

    if (!$toggleButton.length) {
        return;
    }

    $toggleButton.on('touchstart click', function(e) {
        e.preventDefault();
        $drawerWraper.toggleClass('is_open');
        $(this).toggleClass('is_open');
        $('body').toggleClass('locked');

        if((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i))) {
            $('body').toggleClass('locked-iphone');
        }
    });

};

sw.toggleSearch = function() {

    var $toggleButton = $('.js_toggleSearch'),
        $drawerWraper = $('.search_popup');

    if (!$toggleButton.length) {
        return;
    }

    $toggleButton.on('touchstart click', function(e) {
        e.preventDefault();
        $drawerWraper.toggleClass('search_popup--visible');
        $('body').addClass('locked');
        if( $isiphone ) {
            $('body').addClass('locked-iphone');
        }
        $('.search_popup__input').focus();
    });
};


// sw.injectLanguageSwitcher = function() {

//     var $languageItems = $('.language__list li'),
//         $menu = $('.menu__list');

//     if (!$languageItems.length) {
//         return;
//     }

//     $languageItems.appendTo($menu);
//     $('.language__list').remove();
// };


$(document).ready(function (){
    sw.toggleMenu();
    sw.toggleSearch();
});


$(document).on('keyup', function(e) {
    if (e.keyCode === 27) {
        $('.search_popup').removeClass('search_popup--visible');
        $('body').removeClass('locked');
    }
});

$(".search_popup").click(function(){
    $('.search_popup').removeClass('search_popup--visible');
    
    var $ismenuopen = $('.js_toggleMenu').hasClass('is_open');

    if( !($ismenuopen) ) {
        $('body').removeClass('locked');
        if ($isiphone) {
            $('body').removeClass('locked-iphone');
        }
    } else {

    }
});

$(".search_popup__input").click(function(e){
    e.stopPropagation();
});

// Check is vh unit supported
if (Modernizr.cssvhunit) {
  // supported
} else {
    $(window).on('resize', function(event) {
        $ww = $(window).width();

        if($ww < 1024) {
            $finalheight = $(window).height() - 74;
            $('.menu').height($finalheight);
        }
        else {
            $('.menu').height('auto');
        }
    });
}